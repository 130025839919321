import {
  getBizcolumns,
  getHotBizinfos,
} from '../../api/info/info.js'

/**
 * 消息记录
 */
const app = {
  state: {
    // 栏目类别
    bizColumnsList: [],
    topHotInfo: [], //热门资讯
  },

  mutations: {
    /**
     * 更改待办事项
     */
    SET_BIZCOLUMNS: (state, List) => {
      state.bizColumnsList = List
    },
    SET_TOPHOTINFO: (state, List) => {
      state.topHotInfo = List
    }
  },

  actions: {
    GetBizcolumns({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {
        getBizcolumns()
          .then(response => {
            console.log(response.data)
            commit('SET_BIZCOLUMNS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    SetTopInfo({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {
        getHotBizinfos({size:7,orderstr: "make_time"})
          .then(response => {
            commit('SET_TOPHOTINFO', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  }
}

export default app