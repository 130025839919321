import { Message } from 'element-ui';
import Lockr from 'lockr';
import {
  getInfo,
  login
} from '../../api/login.js'
import cache from '../../common/utils/cache.js'
/** 记录 导航栏索引 */
const app = {
  state: {
    userInfo: null,
    loginInfo: null,
    Nav: {
      activeIndex: 'home' // 导航目前是第几个
    },
    firstTip: true,
  },

  mutations: {
    SET_NAVACTIVEINDEX: (state, name) => {
      state.Nav.activeIndex = name
    },
    SET_USERINFO: (state, name) => {
      state.userInfo = name
    },
    SET_LOGINFO: (state, name) => {
      state.loginInfo = name
    },
    SET_FirstTip: (state) => {
      state.firstTip = false
    },
  },
  actions: {
    GetInfo({
      state,
      commit
    },token) {
      return new Promise((resolve, reject) => {
        getInfo(token)
          .then(response => {
            // commit('SET_USERINFO', response.data)
            if (!response.data) {
              commit('SET_LOGINFO', null)
              cache.rmAxiosCache()
            } 
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    LoginToPlr({
      commit
    }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password)
          .then(response => {
            commit('SET_LOGINFO', response.data)
            Lockr.set('loginInfo',response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    SetTip({
      commit
    }, firstTip) {
      return new Promise((resolve, reject) => {
        commit('SET_FirstTip')
      })
    },

  }
}

export default app