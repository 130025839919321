/*
 * @Author: shiyihuang 1023661987@qq.com
 * @Date: 2023-07-16 10:19:24
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-07-16 14:47:44
 * @FilePath: /geteway-web/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import mainRouter from "./modules/mainRouter";
import { getToken, setToken } from '@/common/utils/http-token'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    meta: {
      navName: 'login'
    },
    component: () => import('../views/login.vue')
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   meta: {
  //     navName: 'home'
  //   },
  //   component: () => import('../views/Home.vue')
  // },
  mainRouter,

]

const router = new VueRouter({
  routes,
  // 页面刷新时回到顶部
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

const httpToken = getToken();
router.beforeEach(async (to, from, next) => {
  /** 请求头包含授权信息 并且 页面必须授权 直接进入 */
  if (httpToken) {
    next();
  } else {
    await setToken()
    next();
  }
})

export default router