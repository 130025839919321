import store from '@/store'
import Lockr from 'lockr'
import axios from 'axios'

const cache = {
  /**
   * 载入全部登陆信息
   */
  loadingCache: function() {
    if (Lockr.get('loginInfo') ) {
      /** 将用户信息放入缓存 */
      const loginInfo = Lockr.get('loginInfo')
      if (loginInfo) {
        store.commit('SET_LOGINFO', loginInfo)
      }
    }
    store.commit('SET_LOGINFO', Lockr.get('loginInfo'))

  },
  /**
   * 请求和更新登录缓存
   */
  updateAxiosCache: function() {
    axios.defaults.headers.token = Lockr.get('loginInfo').token
    // store.dispatch('GetUserInfo')
  },
  /**
   * 移除登录信息
   * @param {*}
   */
  rmAxiosCache: function() {
    Lockr.rm('loginInfo')
    // Lockr.rm('sessionId')
  }
}

export default cache
