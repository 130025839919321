<template>
  <div class="naMenuContainer">
   <div class="header">
    <div class="poster">
      <!-- <p class="title"></p> -->
      <img class="title" @click="ToHome" src="../../../assets/images/header-logo.png" alt="">
      <div class="searchAndHot-container">
        <!-- 搜索 -->
        <div class="input-container">
          <el-input v-model="searchInp" class="input-search" placeholder="热搜词：政务处理、网上办事、教育政策"></el-input>
          <!-- 就业服务 -->
          <div class="input-search-button" @click="toImageText()">
            <img
              src="../../../assets/images/search.png"
              class="search-icon"
            />
          </div>
        </div>

      </div>

      <div class="loginAndRegister">
        <el-popover placement="bottom"  trigger="hover" >
          <el-button  @click="logout"
            >退出登录</el-button
          >
          <!-- <div class="logininfo"> -->
          <el-avatar
            slot="reference"
            class="avatar"
            v-if="loginInfo"
            :src="loginInfo.headimg"
          >
            <!-- <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/> -->
          </el-avatar>
          <span 
            slot="reference"
           v-if="loginInfo" style="margin-left: 10px">{{
            loginInfo.name
          }}</span>
          <!-- </div> -->
        </el-popover>
        <!-- <span v-if="!loginInfo" class="loginBtn" @click="login()">登录</span> -->
        <img src="../../../assets/images/登录-左.png" alt="">
        <span v-if="!loginInfo" class="loginBtn" @click="login()">登录</span>
        <img src="../../../assets/images/登录-右.png" alt=""></img>
        <!-- <span class="word-img"><img src="../assets/images/矩形.png" /></span> -->
        <!-- <span v-if="!loginInfo" class="registerBtn" @click="register()"
          >注册</span
        > -->
      </div>
    </div>
   </div>
    <div class="NavMenu flex-col">
      <div class="NavMenu-container">
        <!-- 导航 -->
        <div class="NavMenu-bottom">
          <template v-for="(item, index) in navMenuList">
            <PopoverNew
              :key="item.name"
              :open="item.children.length > 0"
              trigger="dualMode"
            >
              <span
                @click="NavMenuHandle(index, item.path, item.meta.navName)"
                :class="[
                  'nav-word',
                  activeIndex === item.meta.navName ? 'wordcolor' : '',
                ]"
                >{{ item.title }}
                <img class="nav-icon" v-if="item.name == 'seven'" src="../../../assets/images/下拉箭头.png" alt=""></img>
              </span>
              
              <template slot="content" v-if="item.children.length > 0">
                <div class="showNav">
                  <div class="showNav-word">
                    <!-- 七大库的列表 -->
                    <span
                      class="showNav-word-wd"
                      @click="swithchNavhandle(element.path)"
                      v-for="(element, it) in item.children"
                      :key="element.name"
                      >{{ element.title }}
                    </span>
                  </div>
                </div>
              </template>
            </PopoverNew>
          </template>
          <!-- <span class="nav-word">合作院校</span> -->
        </div>
      </div>
      <div class="navFooter" v-if="showBottom"></div>
    </div>
  </div>
</template>

<script>
import PopoverNew from "../../../components/PopoverNew.vue";
import { mapGetters } from "vuex";
// import Lockr from 'lockr'
import cache from "../../../common/utils/cache";
import store from "@/store";

export default {
  name: "HomeNav",
  props: {
    showBottom: {
      // 总页数
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  components: { PopoverNew },
  computed: {
    ...mapGetters(["activeIndex", "loginInfo"]),
  },
  data() {
    return {
      navMenuList: [
        {
          title: "首页",
          name: "home",
          path: "/",
          meta: {
            navName: "home",
          },
          children: [],
        },
        {
          title: "政策公告",
          name: "ImageText",
          path: "/ImageText",
          meta: {
            navName: "ImageText",
          },
          children: [],
        },
        {
          title: "找合作",
          path: "/demand",
          name: "demand",
          meta: {
            navName: "Demand",
          },
          children: [],
        },
        {
          title: "职业培训",
          path: "/Vocational",
          name: "Vocational",
          meta: {
            navName: "Vocational",
          },
          children: [],
        },
        {
          title: "人才招聘",
          name: "otherLink",
          meta: {
            navName: "otherLink",
          },
          // path: "http://lmu-demo-job.test-in.goktech.cn/",
          path: "https://job.qzcjrh.cn/",
          children: [],
        },
        {
          title: "产融型企业",
          name: "Industry",
          children: [],
          meta: {
            navName: "Industry",
          },
          path: "/Industry",
        },
        {
          title: "合作院校",
          name: "school",
          children: [],
          path: "/school",
          meta: {
            navName: "school",
          },
        },
        {
          title: "职教集团",
          name: "group",
          children: [],
          path: "/group",
          meta: {
            navName: "group",
          },
        },
        {
          title: "七大库",
          name: "seven",
          meta: {
            navName: "seven",
          },
          children: [
            {
              title: "产学研平台库",
              path: "/LsrPlatform",
              name: "LsrPlatform",
            },
            { title: "实训基地库", path: "/Base", name: "base" },
            { title: "专家库", path: "/Professor", name: "Professor" },
            { title: "成果库", path: "/Achievement", name: "Achievement" },
            { title: "设备库", path: "/Facility", name: "Facility" },
            { title: "项目库", path: "/Project", name: "Project" },
            { title: "双师资源库", path: "/Teachers", name: "Teachers" },
          ],
        },
        {
          title: "云平台",
          name: "cloudPlatform",
          meta: {
            navName: "cloudPlatform",
          },
          // path: "http://lmu-demo-data.test-in.goktech.cn/",
          path: "http://screen.qzcjrh.cn/",
          children: [],
        },
        // "首页","政策公告","找合作","职业培训","人才招聘",
        // "产融型企业","六大库"
      ],
      // activeIndex: "0",
      openeds: ["1"],
      drawer: false,
      userInfo: {},
      searchInp: "",
      // 热词列表
      // hotWord: [
      //   {
      //     name: "政务处理",
      //   },
      //   {
      //     name: "网上办事",
      //   },
      //   {
      //     name: "教育政策",
      //   },
      //   {
      //     name: "就业服务",
      //   }
      // ],
    };
  },
  mounted() {},
  created() {
    this.GetBizcolumnsList();
    this.GetTechField();
    this.GetInfo();
  },

  methods: {
    logout() {
      store.commit("SET_LOGINFO", null);
      // Lockr.rm('loginInfo')
      cache.rmAxiosCache();
      window.location.reload();
    },
    toImageText() {
      if (!this.searchInp) {
        return;
      }
      this.$router.push({
        path: "/imageText",
        query: { keyword: this.searchInp },
      });
    },
    // sendIndex(name) {
    //   this.hotWord.forEach((item) => {
    //     if (item.name == name) {
    //       this.searchInp = name;
    //     }
    //   });
    // },
    login() {
      window.open("http://lmu-demo-back.test-in.goktech.cn");
    },
    register() {
      window.open("http://oa.sinkr.cn/common/register.html");
    },
    GetTechField() {
      this.$store
        .dispatch("GetTechfield")
        .then((res) => {
          this.newsRight = res.data;
          this.news = res.data.slice(0, 4);
        })
        .catch(() => {});
    },
    GetInfo() {
      this.$store
        .dispatch("GetInfo", {
          token: this.loginInfo ? this.loginInfo.token : "",
        })
        .then((res) => {
          this.newsRight = res.data;
          this.news = res.data.slice(0, 4);
        })
        .catch(() => {});
    },
    GetBizcolumnsList() {
      this.$store
        .dispatch("GetBizcolumns")
        .then((res) => {})
        .catch(() => {});
    },
    NavMenuHandle(val, path, name) {
      if (name === "otherLink" || name === "cloudPlatform") {
        window.open(path);
        return;
      }
      this.$store.commit("SET_NAVACTIVEINDEX", name);
      // this.activeIndex = val.toString();
      path && this.$router.replace(path).catch();
    },
    swithchNavhandle(path) {
      console.log(path)
      this.$router.replace(path);
    },
    ToHome() {
      if (this.$route.path != "/") {
        this.$router.replace("/");
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* 头部 */
.header {
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: center;
  background: #ffffff;
}
.poster {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;

  .title {
    // width: 590px;
    height: 66px;
    // font-size: 30px;
    // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    // font-weight: 400;
    // color: #ffffff;
    // line-height: 36px;
    // white-space: nowrap;
    // background: url(../../../assets/images/首页标题.png) no-repeat;
    // background-position: 0px -66px;
  }
  .loginAndRegister {
    width: 131px;
    height: 66px;
    color: #ffffff;
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
    background: url(../../../assets/images/世界地图.png);

    ::v-deep .el-popover__reference-wrapper {
      display: flex;
      align-items: center;
    }
    .logininfo {
      display: flex;
    }
    .avatar {
      height: 40px;
      width: 40px;
    }
    span {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      display: block;
      height: 16px;
      line-height: 16px;
    }
    img {
      height: 22px;
      width: 20px;
    }
    .loginBtn {
      color: #2885ff;
      font-size: 16px;
      font-weight: 600;
      position: relative;
      // font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-family: 思源黑体 CN Medium;
      margin: 0px 12px;
      // &::after {
      //   position: absolute;
      //   top: 0px;
      //   right: -13px;
      //   width: 2px;
      //   height: 16px;
      //   content: "";
      //   background: #ffffff;
      // }
      &:hover {
        margin: 0px 22px;
      }
    }
    .registerBtn {
      margin-left: 28px;
    }
  }
  /* 搜索框和热词的容器 */
  .searchAndHot-container {
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 159px;
    position: relative;
    /* 搜索框 */
    .input-container {
      position: relative;
      width: 300px;
      height: 34px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #f1f1f1;
      &:hover {
         border: 1px solid #2885FF;
      }
      .input-search {
        width: 289px;
        flex: 1;
        /* height: 62px; */
        background: #ffffff;
        // height: 18px;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        margin-left: 12px;
        // line-height: 18px;
        color: #333333;
        border: none;
        outline: none;
        ::v-deep input {
          height: 34px;
          padding-left: 0;
          border: none;
        }
      }
      .input-search-button {
        height: 36px;
        width: 34px;
        box-sizing: border-box;
        background-color: #2885ff;
        // border: 1px solid #2885FF;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-left: 8px;
        border-radius: 0px 6px 6px 0px;
        cursor: pointer;
        &:hover {
          background-color: #005FDB;
        }
        img {
          width: 12px;
          height: 12px;
        }
      }

      /* 输入框里的文字 */
      input::placeholder {
        height: 11px;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        line-height: 14px;
        color: #999;
      }
    }
    /* 热词 */
    .hotWord {
      margin-top: 18px;
      width: 100%;
      .hotWord-text {
        display: block;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.wordcolor {
  color: #ffffff;
  background-color: #005fdb;
  font-size: 14px;
  font-weight: 600;
}
.NavMenu {
  width: 100%;
  height: 40px;
  background-color: #2885ff;
  color: #fff;
  // position: sticky;
  //  top: 0;
  z-index: 999;
}
.NavMenu-container {
  // height: 100%;
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  // width: 1000px;
  margin: 0 auto;
  min-width: 1000px;
}
/**
  上面文字+登陆注册
*/
.NavMenu-Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 27px;
  .info1 {
    cursor: default;
    width: 598px;
    font-size: 46px;
    font-family: MicrosoftYaHei;
    color: #dc1804;
    white-space: nowrap;
    font-weight: 500;
    display: block;
  }

  .loginAndRegister {
    width: 92px;
    height: 16px;
    color: #333333;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    // margin-left: 40px;
    cursor: pointer;
    .avatar {
      height: 40px;
      width: 40px;
    }
    span {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      display: block;
      height: 16px;
      line-height: 16px;
    }
    .loginBtn {
      position: relative;

      &::after {
        position: absolute;
        top: 0px;
        right: -13px;
        width: 2px;
        height: 16px;
        content: "";
        background: #333333;
      }
    }
    .registerBtn {
      margin-left: 28px;
    }
  }
}

/* 导航 */
.NavMenu-bottom {
  display: flex;
  justify-content: space-between;
  position: relative;

}
.nav-word {
  height: 40px;
  display: inline-block;
  padding: 0 30px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    font-weight: 600;
    // background-color: #005FDB;
    .nav-icon {
      transform: rotate(-180deg);
      transition: linear 0.2s;
    }
  }
  .nav-icon {
    width: 10px;
    height: 6px;
    line-height: 10px;
    vertical-align: middle;
    transform: rotate(0deg);
    transition: linear 0.2s;
  }
}

/* 导航显示 */
.showNav {
  // background: red;
  width: 156px;
  height: 269px;
  // line-height: 80px;
  margin-top: -2px;
  box-shadow: 0px 12px 50px rgba($color: #000000, $alpha: 0.16);
  // background: red;
  
}
.showNav-word {
  width: 144px;
  height: 255px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 6px;
}
.showNav-word-wd {
  display: inline-block;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  display: block;
  width: 134px;
  height: 36px;
  text-align: left;
  padding-left: 10px;
  line-height: 36px;
  color: #101010;
  cursor: pointer;

  // margin-bottom: 15px;
  &:hover {
    // color: #316BAB;
    background-color: #f6f6f6;
    font-weight: 600;
  }
}
.media-NavMenu {
  display: none;
}
.subMenu {
  font-size: 24px;
}
.logo {
  cursor: pointer;
}
.logo > img {
  width: 100%;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
ul {
  display: flex;
  flex-direction: column;
}
.navFooter {
  width: 100%;
  height: 14px;
  background: #14569f;
}
</style>

<style>
.el-submenu__title i {
  display: none;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  text-align: center;
  min-width: 100px !important;
}
</style>

<style scoped lang="scss">
/* ipad pro */
@media screen and (max-width: 1024px) {
}
/* ipad */
@media screen and (max-width: 768px) {
  // .NavMenu{
  //   display: none;
  // }
  // .navFooter{
  //   display: none;
  // }
}
/* iphone6 7 8 plus */
@media screen and (max-width: 414px) {
  body {
    background-color: blue;
  }
}
/* iphoneX */
@media screen and (max-width: 375px) and (-webkit-device-pixel-ratio: 3) {
  body {
    background-color: #0ff000;
  }
}
/* iphone6 7 8 */
@media screen and (max-width: 375px) and (-webkit-device-pixel-ratio: 2) {
  body {
    background-color: #0ff000;
  }
}
/* iphone5 */
@media screen and (max-width: 320px) {
  body {
    background-color: #0ff000;
  }
}
</style>