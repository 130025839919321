<!--
 * @Author: shiyihuang 1023661987@qq.com
 * @Date: 2023-07-16 10:42:00
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-07-16 14:52:44
 * @FilePath: /geteway-web/src/views/Layout/Layout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="MainContainer" v-if="isShow">
    <HomeNav></HomeNav>
    <app-main></app-main>
    <Footer></Footer>
  </div>
</template>

<script>
import { NavMenu, Footer, AppMain, HomeNav } from "./components";
import { getToken } from "@/api/login.js";
export default {
  name: "Layout",
  components: { AppMain, NavMenu, Footer, HomeNav },

  data() {
    return {
      isShow: true, // 是否显示页面,取决于是否获取到token
    };
  },

  created() {
    // this.init();
  },

  methods: {
    async init() {
      this.isShow = false
      const res = await getToken()
      console.log(res, '131313');
      // 存储token
      this.isShow = true
    },
  },

};
</script>
<style lang="scss" scoped>
.MainContainer {
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  min-height: 100%;
}
</style>>