function convertIdeogramToNormalCharacter(val) {
    const arrEntities = {'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
    return val.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];});
  }
  
  // 获取富文本的纯文字内容
export const getPlainText = (richCont) => {
    const str = richCont;
    let value = richCont;
    if (richCont) {
        // 方法一： 
        value = value.replace(/\s*/g, "");  //去掉空格
        value = value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
        value = value.replace(/↵/g, "");     //去掉所有的↵符号
        value = value.replace(/[\r\n]/g, "") //去掉回车换行
        value = value.replace(/&nbsp;/g, "") //去掉空格
        value = convertIdeogramToNormalCharacter(value);
        return value;
  
        // 方法二： 
        // value = value.replace(/(\n)/g, "");
        // value = value.replace(/(\t)/g, "");
        // value = value.replace(/(\r)/g, "");
        // value = value.replace(/<\/?[^>]*>/g, "");
        // value = value.replace(/\s*/g, "");
        // value = convertIdeogramToNormalCharacter(value);
        // return value;
    } else {
        return null;
    }
}

// 深拷贝
export function objDeepCopy(source) {
    if (typeof source === 'object') {
      var sourceCopy = source instanceof Array ? [] : {}
      for (var item in source) {
        if (!source[item]) {
          sourceCopy[item] = source[item]
        } else {
          sourceCopy[item] = typeof source[item] === 'object' ? objDeepCopy(source[item]) : source[item]
        }
      }
      return sourceCopy
    }
    return source
}

export const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  console.log(23333,this,fn,t);

  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

export function throttle(fn, interval) {
  var last;
  var timer;
  var interval = interval || 200;
  return function () {
      var th = this;
      var args = arguments;
      var now = +new Date();
      if (last && now - last < interval) {
          clearTimeout(timer);
          timer = setTimeout(function () {
              last = now;
              fn.apply(th, args);
          }, interval);
      } else {
          last = now;
          fn.apply(th, args);
      }
  }
}