/*
 * @Author: shiyihuang 1023661987@qq.com
 * @Date: 2023-07-16 13:59:31
 * @LastEditors: sikonggpw 1327325804@qq.com
 * @LastEditTime: 2023-09-06 20:39:36
 * @FilePath: /data-view/src/utils/http-token.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
export const TOKEN_NAME = 'cjrhToken'
const test = 'ff808081891ad29e018959b3828300c8'
const dev = '1183b0b84ee4f581bba001c47a78b2d9'
const tokenHttp = axios.create({ baseURL: 'https://console.qzcjrh.cn/itf/common/', timeout: 300 * 1000 });
export const getToken = () => {
  const token = localStorage.getItem(TOKEN_NAME)
  return token
}
export const setToken = () => {
  return new Promise((r, j) => {
    tokenHttp.get('login/getToken.json', {params: { pk_user: test }}).then(res => {
      const token = res.data.token;
      localStorage.setItem(TOKEN_NAME, token)
      r(res);
    }).catch(err => {
      j(err)
    })
  })
}