import request from "../../../common/utils/request";

// 获取产学库信息
export function getLsrPlatformInfo(params) {
  return request({
    url: "/itf/common/cjrh/platform/getPlatforms.json",
    method: "get",
    params:params,
  });
}


// 根据id查询详细信息
export function toPageByid(data) {
  return request({
    url: "/itf/common/cjrh/platform/getPlatformById.json",
    method: "get",
    params: data
  });
}
