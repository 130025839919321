<template>
  <div id="app">
    <router-view v-if="isRouterShow" />
  </div>
</template>
<script>
import { setToken } from '@/common/utils/http-token'
export default {
  computed: {
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterShow: true,
    };
  },
  methods: {
    async reload() {
      this.isRouterShow = false;
      await this.$nextTick();
      // await setToken()
      this.isRouterShow = true;
    },
  },
  async created() {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("list", JSON.stringify(this.$store.state));
    });

    await setToken()

    try {
      sessionStorage.getItem("list") &&
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem("list"))
          )
        );
    } catch (err) {
    }
    sessionStorage.removeItem("list");
  },
  components: {},
  watch: {
    $route(to, from) {
      if (to.meta.navName) {
        this.$store.commit("SET_NAVACTIVEINDEX", to.meta.navName);
      }
    },
  },
};
</script>
<style>
#app {
  height: 100%;
  width: 100%;
}

.slide-left-enter {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-enter-active {
  transition: all .5s ease;
}

.slide-left-leave-to {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.slide-left-leave-active {
  transition: all .5s ease;
}

@font-face {
  font-family: '优设标题黑';
  src: url('../src/assets/font/优设标题黑.ttf');
}

@font-face {
  font-family: 'Bold';
  src: url('../src/assets/font/SourceHanSansCN-Bold.otf');
}

@font-face {
  font-family: 'Medium';
  src: url('../src/assets/font/SourceHanSansCN-Medium.otf');
}

@font-face {
  font-family: 'Regular';
  src: url('../src/assets/font/SourceHanSansCN-Regular.otf');
}</style>
