/*
 * @Author: sikonggpw 1327325804@qq.com
 * @Date: 2023-02-23 14:37:09
 * @LastEditors: sikonggpw 1327325804@qq.com
 * @LastEditTime: 2023-04-03 10:45:12
 * @FilePath: \gateway-web\src\api\home\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../../common/utils/request'

// 查询最新四条信息
export function getTop4Bizinfo(data) {
  return request({
    url: "/itf/common/cjrh/home/getTop4Bizinfo.json",
    method: "get",
    params: data
  });
}

// 查询最新四条信息
export function getIndustryinfoDay(data) {
  return request({
    url: "/itf/common/cjrh/home/getIndustryinfoDay.json",
    method: "get",
    params: data
  });
}
// 查询n条产业集群库
export function getTopBizHomeindus(data) {
  return request({
    url: "/itf/common/cjrh/home/getTopBizHomeindus.json",
    method: "get",
    params: data
  });
}
// 查询n条产业集群库
export function getNewestEnterprise(data) {
  return request({
    url: "/itf/common/cjrh/industry/getNewestEnterprise.json",
    method: "get",
    params: data
  });
}
// 查询n条院校集群库
export function getTopBizInstitution(data) {
  return request({
    url: "/itf/common/cjrh/home/getTopBizInstitution.json",
    method: "get",
    params: data
  });
}
// 查询技术领域
export function getTechfield(data) {
  return request({
    // url: "/itf/common/cjrh/home/getTechfield.json",
    url: "/itf/common/cjrh/home/getTechfield.json",
    method: "get",
    params: data
  });
}

// 查询行业领域
export function getIndustryfield(data) {
  return request({
    url:"/itf/common/cjrh/home/getIndustryfield.json",
    method:"get",
    params:data
  });
}
