import Vue from 'vue'
import Vuex from 'vuex'
import Info from './modules/Info'
import app from './modules/app'
import home from './modules/home'
import lsrPlatform from './modules/lsrPlatform'
import getters from './getters'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    // app,
    // permission,
    // oa,
    // user,
    app,
    Info,
    home,
    lsrPlatform
  },
  getters
})
console.info(getters)
export default store
