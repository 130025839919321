<template>
  <div class="footBox">
    <div class="Footer">
      <div class="footer-container">
        <!-- 服务列 -->
        <div class="container2">
          <div class="wz-title" >服务协议</div>
          <div class="wz-title-context" @click="toRegistry()">服务注册协议</div>
          <div class="wz-title-context" @click="toServiceAgreemrnt()">网站服务协议</div>
        </div>
        <!-- 网站列 -->
        <div class="container2-wz">
          <div class="wz-title">网站声明</div>
          <div class="wz-title-context" @click="toPardon()">免责声明</div>
          <div class="wz-title-context" @click="toPrivacy()">隐私保护</div>
          <div class="wz-title-context" @click="toLaw()">法律声明</div>
        </div>
        <!-- 关于我们 -->
        <div class="container2-lx">
          <div class="wz-title">联系我们</div>
          <div class="wz-title-context">地址：福建省泉州市丰泽区通港西街298号</div>
          <div class="wz-title-context">电话：0595-22907977</div>
          <!-- <div class="wz-title-context">邮箱：qzcjrh&#64;163.com</div> -->
          <div class="wz-title-context">传真：0595-22918928</div>
        </div>
        <!-- 公众号 -->
        <!-- <div class="wx-container2">
          <div class="wz-title">微信公众号</div>
          <div class="wx-img-container">
            <img src="../../../assets/images/wxgzh.png" alt="" />
          </div>
        </div> -->
      </div>
      <div class="footer-line"></div>
      <!-- 版权号 -->
      <div class="copyright">
        <span class="copyright-left"
          >版权所有&nbsp;©&nbsp;2022&nbsp;&nbsp;</span
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="copyright-right" @click="toreFerence"><span style="margin-right:10px">备案号:</span>闽ICP备11007778号-2</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footerList: [
        { title: "服务协议", info: ["服务注册协议", "网站服务协议"] },
        { title: "网站声明", info: ["免责声明", "隐私声明", "法律声明"] },
        {
          title: "联系我们",
          info: [
            "电话：0595-255XXXX",
            "邮箱：qzcjrh@163.com",
            "传真：059X-83441XXX",
          ],
        },
      ],
    };
  },
  methods: {
    toRegistry(){
      this.$router.push({
        path:'/Registry',
      })
    },
    toServiceAgreemrnt() {
      this.$router.push({
        path:'/ServiceAgreemrnt',
      })
    },
    toPardon() {
      this.$router.push({
        path:'/Pardon',
      })      
    },
    toPrivacy() {
      this.$router.push({
        path:'/Privacy',
      })  
    },
    toLaw() {
      this.$router.push({
        path:'/Law',
      })  
    },
    toreFerence() {
      window.open(
        "https://beian.miit.gov.cn/"
      )
    }
  }
};
</script>

<style scoped lang="scss">
.Footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // background: #14569f;
  padding-top: 35px;
  border-top: 1px solid #f1f1f1;
  width: 100%;
  height: 230px;
}
.footer-container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 186px;
  background: url(../../../assets/images/home-footer.png) no-repeat;
  background-position: 50% 100%;
}
.wz-title {
  font-size: 14px;
  font-family: Medium;
  font-weight: 400;
  color: #2885ff;
}
.wz-title-context {
  margin-top: 23px;
  font-size: 12px;
  font-family: Regular;
  font-weight: 400;
  color: #404040;
  &:hover {
    color: #2885ff;
  }
}
.footer-line {
  /* width: 100%; */
  height: 1px;
  border: 1px solid #f1f1f1;
  border-top: none;
  // margin-top: 53px;
  margin-bottom: 16px;
}
.wx-container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .wz-title {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #2885ff;
    margin-bottom: 23px;
  }
}
.wx-img-container {
  width: 119px;
  height: 120px;
  padding: 13px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    height: 100%;
    width: 100%;
  }
}
.copyright {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.copyright-left {
  font-size: 12px;
  font-family: SRegular;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.copyright-right {
  font-size: 12px;
  font-family: Regular;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  cursor: pointer;
}
.container2 {
  .wz-title {
    font-size: 14px;
    font-family: Medium;
    font-weight: 400;
    color: #2885ff;
    cursor: pointer;
  }
  .wz-title-context {
    cursor: pointer;
    font-size: 12px;
    font-family: Regular;
    font-weight: 400;
    color: #404040;
    &:hover {
      color: #2885ff;
    }
  }
}

.container2-wz {
  .wz-title {
    font-size: 14px;
    font-family: Medium;
    font-weight: 400;
    color: #2885ff;
    cursor: pointer;
  }
  .wz-title-context {
    cursor: pointer;
    font-size: 12px;
    font-family: Regular;
    font-weight: 400;
    color: #404040;
    &:hover {
      color: #2885ff;
    }
  }
}

.container2-lx {
  .wz-title {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #2885ff;
    cursor: pointer;
  }
  .wz-title-context {
    cursor: pointer;
    font-size: 12px;
    font-family: Regular;
    font-weight: 400;
    color: #404040;
    &:hover {
      color: #2885ff;
    }
  }
}

@media screen and (max-width: 992px) {
}
</style>
