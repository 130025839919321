<template>
  <div class="AppMain">
    <transition name="slide-left" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    
  },
};
</script>

<style scoped>
.AppMain {
  /* background: #ededed; */
  /* height: 100%;  */
  min-width: 960px;
  flex: 1;
}
</style>
