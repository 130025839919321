<template>
  <div v-Clickoutside="close" :class="['popContainer']" ref="popover">
    <div class="popover">
      <slot></slot>
      <!-- 三角形 -->
      <!-- <div class="sjx" v-show="visible && open">
      </div> -->
    </div>
    <div class="content-wrapper" v-show="visible">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
import Clickoutside from "@/common/utils/clickoutside.js";
import { Debounce, throttle } from "@/common/utils/tool.js";
export default {
  name: "Popover",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: String,
      default: "click",
      validator(value) {
        return ["click", "hover", "dualMode"].indexOf(value) >= 0;
      },
    },
  },
  data() {
    return { visible: false };
  },
  directives: { Clickoutside },
  mounted() {
    if (this.trigger === "click") {
      this.$refs.popover.addEventListener("click", this.handleClick);
    } else {
      this.$refs.popover.addEventListener("mouseenter", this.hoverOnshow);
      this.$refs.popover.addEventListener("mouseleave", this.hoverClose);
    }
  },
  beforeDestroy() {
    if (this.trigger === "click") {
      this.$refs.popover.removeEventListener("click", this.handleClick);
    } else {
      this.$refs.popover.removeEventListener("mouseenter", this.hoverOnshow);
      this.$refs.popover.removeEventListener("mouseleave", this.hoverClose);
    }
  },
  methods: {
    handleClick(event) {
      if (this.visible === true) {
        this.close();
      } else {
        this.onShow();
      }
    },
    eventHandler(e) {
      if (
        this.$refs.popover &&
        (this.$refs.popover === e.target ||
          this.$refs.popover.contains(e.target))
      ) {
        return;
      }
      if (
        this.$refs.popoverWrapper &&
        (this.$refs.popoverWrapper === e.target ||
          this.$refs.popoverWrapper.contains(e.target))
      ) {
        return;
      }
      this.close();
    },
    onShow() {
      this.visible = true;
    },
    hoverOnshow() {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.visible = true;
      }, 200);
    },
    hoverClose() {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.visible = false;
      }, 200);
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.popContainer {
  z-index: 1003;
}
.popover {
  display: inline-block;
  position: relative;
  /* 三角 */
  .sjx {
    top: 35px;
    left: 50%;
    position: absolute;
    height: 20px;
    width: 20px;
    background: #458ad6;
    transform: translateX(-50%);
    transform: rotate(45deg);
  }
}
.content-wrapper {
  // top: 45px;
  position: absolute;
  background: #ffffff;
  // box-shadow: 0px 6px 4px 1px rgba(197, 197, 197, 0.5);
  // border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 12px 50px rgba($color: #000000, $alpha: 0.16);
  border-radius: 6px;
  // opacity: 0.8;
  margin-top: 6px;
}
</style>