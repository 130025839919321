/*
 * @FilePath: /gateway-web/src/main.js
 * @Author: caigengliang
 * @Date: 2022-03-15 12:05:57
 * @LastEditTime: 2022-08-01 09:31:26
 * @LastEditors: name
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './common/utils/rem';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from "./store";
import "./assets/css/base.css"; // global css
import "./assets/css/reset.css"; // global css
import empty from "./directives/empty";
import './directives/empty/empty.scss';
import cache from "./common/utils/cache.js";
cache.loadingCache();
// import "./permission"; 
import "./directives/empty";
Vue.use(ElementUI);
Vue.use(empty);
Vue.config.productionTip = false

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
