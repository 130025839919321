<template>
  <div class="NavMenu flex-col">
    <div class="NavMenu-container">
      <div class="NavMenu-Top">
        <span class="info1">泉州市产教融合综合服务平台</span>
        <div class="loginAndRegister">
          <el-avatar class="avatar" v-if="loginInfo" :src="loginInfo.headimg">
            <!-- <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/> -->
          </el-avatar>
          <span v-if="loginInfo" style="margin-left: 10px">{{
            loginInfo.name
          }}</span>

          <span v-if="!loginInfo" class="loginBtn" @click="login()">登录</span>
          <!-- <span class="word-img"><img src="../assets/images/矩形.png" /></span> -->
          <span v-if="!loginInfo" class="registerBtn" @click="register()"
            >注册</span
          >
        </div>
      </div>

      <!-- 导航 -->
      <div class="NavMenu-bottom">
        <template v-for="(item, index) in navMenuList">
          <Popover
            :key="item.name"
            :open="item.children.length > 0"
            trigger="hover"
          >
            <span
              @click="NavMenuHandle(index, item.path, item.meta.navName)"
              :class="[
                'nav-word',
                activeIndex === item.meta.navName ? 'wordcolor' : '',
              ]"
              >{{ item.title }}</span
            >
            <template slot="content" v-if="item.children.length > 0">
              <div class="showNav">
                <div class="showNav-word">
                  <span
                    class="showNav-word-wd"
                    @click="swithchNavhandle(element.path)"
                    v-for="(element, it) in item.children"
                    :key="element.name"
                    >{{ element.title }}</span
                  >
                </div>
              </div>
            </template>
          </Popover>
        </template>
        <!-- <span class="nav-word">合作院校</span> -->
      </div>
    </div>
    <div class="navFooter" v-if="showBottom"></div>
  </div>
</template>

<script>
import Popover from "../../../components/PopoverNew.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    showBottom: {
      // 总页数
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  components: { Popover },
  computed: {
    ...mapGetters(["activeIndex", "loginInfo"]),
  },
  data() {
    return {
      navMenuList: [
        {
          title: "首页",
          name: "home",
          path: "/",
          meta: {
            navName: "home",
          },
          children: [],
        },
        {
          title: "政策公告",
          name: "ImageText",
          path: "/ImageText",
          meta: {
            navName: "ImageText",
          },
          children: [],
        },
        {
          title: "找合作",
          path: "/demand",
          name: "demand",
          meta: {
            navName: "Demand",
          },
          children: [],
        },
        {
          title: "职业培训",
          path: "/Vocational",
          name: "Vocational",
          meta: {
            navName: "Vocational",
          },
          children: [],
        },
        {
          title: "人才招聘",
          name: "otherLink",
          meta: {
            navName: "otherLink",
          },
          path: "https://job.qzcjrh.cn/",
          children: [],
        },
        {
          title: "产融型企业",
          name: "Industry",
          children: [],
          meta: {
            navName: "Industry",
          },
          path: "/Industry",
        },
        {
          title: "合作院校",
          name: "school",
          children: [],
          path: "/school",
          meta: {
            navName: "school",
          },
        },
        {
          title: "六大库",
          name: "six",
          meta: {
            navName: "six",
          },
          children: [
            {
              title: "产学研平台库",
              path: "/LsrPlatform",
              name: "LsrPlatform",
            },
            { title: "实训基地库", path: "/Base", name: "base" },
            { title: "专家库", path: "/Professor", name: "Professor" },
            { title: "成果库", path: "/Achievement", name: "Achievement" },
            { title: "设备库", path: "/Facility", name: "Facility" },
            { title: "项目库", path: "/Project", name: "Project" },
          ],
        },
        // "首页","政策公告","找合作","职业培训","人才招聘",
        // "产融型企业","六大库"
      ],
      // activeIndex: "0",
      openeds: ["1"],
      drawer: false,
      userInfo: {},
    };
  },
  mounted() {},
  created() {
    this.GetBizcolumnsList();
    this.GetTechField();
    this.GetInfo();
  },

  methods: {
    login() {
      this.$router.push("/login");
    },
    register() {
      window.open("http://oa.sinkr.cn/common/register.html");
    },
    GetTechField() {
      this.$store
        .dispatch("GetTechfield")
        .then((res) => {
          this.newsRight = res.data;
          this.news = res.data.slice(0, 4);
        })
        .catch(() => {});
    },
    GetInfo() {
      this.$store
        .dispatch("GetInfo", {
          token: this.loginInfo ? this.loginInfo.token : "",
        })
        .then((res) => {
          this.newsRight = res.data;
          this.news = res.data.slice(0, 4);
        })
        .catch(() => {});
    },
    GetBizcolumnsList() {
      this.$store
        .dispatch("GetBizcolumns")
        .then((res) => {})
        .catch(() => {});
    },
    NavMenuHandle(val, path, name) {
      if (name === "otherLink") {
        window.open(path);
        return;
      }
      this.$store.commit("SET_NAVACTIVEINDEX", name);
      // this.activeIndex = val.toString();
      path && this.$router.replace(path);
    },
    swithchNavhandle(path) {
      console.log(path)
      this.$router.replace(path);
    },
    ToHome() {
      if (this.$route.path != "/") {
        this.$router.replace("/");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wordcolor {
  color: #14569f;
}
.NavMenu {
  width: 100%;
  height: 191px;
  background-color: #fff;
}
.NavMenu-container {
  // height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 68.75%;
  margin: 0 auto;
}
/**
  上面文字+登陆注册
*/
.NavMenu-Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 27px;
  .info1 {
    cursor: default;
    width: 598px;
    font-size: 46px;
    font-family: MicrosoftYaHei;
    color: #dc1804;
    white-space: nowrap;
    font-weight: 500;
    display: block;
  }

  .loginAndRegister {
    color: #333333;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .avatar {
      height: 40px;
      width: 40px;
    }
    span {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      display: block;
      height: 16px;
      line-height: 16px;
    }
    .loginBtn {
      position: relative;
      &::after {
        position: absolute;
        top: 0px;
        right: -13px;
        width: 2px;
        height: 16px;
        content: "";
        background: #333333;
      }
    }
    .registerBtn {
      margin-left: 28px;
    }
  }
}

/* 导航 */
.NavMenu-bottom {
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
  margin-bottom: 26px;
  position: relative;
}
.nav-word {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  line-height: 36px;
  cursor: pointer;
}

/* 导航显示 */
.showNav {
  width: 100%;
  // line-height: 80px;
}
.showNav-word {
  width: 68.75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 19px 0px 0px 0px;
}
.showNav-word-wd {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  display: block;
  width: 152px;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 15px;
  &:hover {
    font-weight: 600;
  }
}
.media-NavMenu {
  display: none;
}
.subMenu {
  font-size: 24px;
}
.logo {
  cursor: pointer;
}
.logo > img {
  width: 100%;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
ul {
  display: flex;
  flex-direction: column;
}
.navFooter {
  width: 100%;
  height: 14px;
  background: #14569f;
}
/* 手机屏幕*/
@media screen and (max-width: 768px) {
}
/* 平板电脑*/
@media screen and (min-width: 768px) and (max-width: 992px) {
}
/* 笔记本电脑*/
@media screen and (min-width: 992px) and (max-width: 1200px) {
}

@media screen and (max-width: 1200px) {
  /* 低于1200px出现媒体查询导航条 */
}
/* 台式电脑 */
@media screen and (min-width: 1200px) {
}
</style>

<style>
.el-submenu__title i {
  display: none;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  text-align: center;
  min-width: 100px !important;
}
</style>
