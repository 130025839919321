/** 内容界面 */
import Layout from '../../views/Layout/Layout.vue'
const maintRouter = {
  path: '/',
  component: Layout,
  name: 'layout',
  children: [
    /* This is a router. */
    {
      path: '/',
      name: 'Home',
      meta: {
        navName: 'home'
      },
      component: () => import('../../views/Home.vue')
    },
    {
      path: '/ApplyForIndustry',
      name: 'ApplyForIndustry',
      meta: {
        navName: 'home'
      },
      component: () => import('../../views/ApplyForIndustry.vue')
    },
    {
      path: '/imageText',
      name: 'ImageText',
      meta: {
        navName: 'ImageText'
      },
      component: () => import('../../views/Info/ImageText.vue')
    },
    {
      path: '/imageText/infoDetails',
      name: 'InfoDetails',
      meta: {
        navName: 'ImageText'
      },
      component: () => import('../../views/Info/InfoDetails.vue')
    },
    {
      path: '/demand',
      name: 'Demand',
      meta: {
        navName: 'Demand'
      },
      component: () => import('../../views/Demand/Demand.vue'),
    },
    {
      path: '/demand/demandDetails',
      name: 'DemandDetails',
      meta: {
        navName: 'Demand'
      },
      component: () => import('../../views/Demand/DemandDetails.vue')
    },
    {
      path: '/demand/issueDemand',
      name: 'IssueDemand',
      meta: {
        navName: 'Demand'
      },
      component: () => import('../../views/Demand/IssueDemand.vue')
    },
    {
      path: '/industry',
      name: 'Industry',
      meta: {
        navName: 'Industry'
      },
      component: () => import('../../views/Industry/Industry.vue'),
    },
    {
      path: '/industry/industryDetails',
      name: 'IndustryDetails',
      meta: {
        navName: 'Industry'
      },
      component: () => import('../../views/Industry/IndustryDetails.vue'),
    },
    {
      path: '/school',
      name: 'School',
      meta: {
        navName: 'school'
      },
      component: () => import('../../views/School/School.vue'),
    },
    {
      path: '/school/schoolDetails',
      name: 'SchoolDetails',
      meta: {
        navName: 'school'
      },
      component: () => import('../../views/School/SchoolDetails.vue'),
    },
    {
      path: '/group',
      name: 'Group',
      meta: {
        navName: 'group'
      },
      component: () => import('../../views/Group/Group.vue'),
    },
    {
      path: '/group/groupDetails',
      name: 'GroupDetails',
      meta: {
        navName: 'group'
      },
      component: () => import('../../views/Group/GroupDetails.vue'),
    },
    {
      path: '/vocational',
      name: 'Vocational',
      meta: {
        navName: 'Vocational'
      },
      component: () => import('../../views/Vocational/Vocational.vue'),
    },
    {
      path: '/vocational/course',
      name: 'vocational',
      meta: {
        navName: 'Vocational'
      },
      component: () => import('../../views/Vocational/Course.vue'),
      redirect: '/vocational/course/intro',
      children: [{
          path: '/vocational/course/intro',
          name: 'Intro',
          meta: {
            navName: 'Vocational'
          },
          component: () => import('../../views/Vocational/Intro.vue')
        },
        {
          path: '/vocational/course/info',
          name: 'Info',
          meta: {
            navName: 'Vocational'
          },
          component: () => import('../../views/Vocational/Info.vue')
        },
        {
          path: '/vocational/course/apply',
          name: 'Apply',
          meta: {
            navName: 'Vocational'
          },
          component: () => import('../../views/Vocational/Apply.vue')
        },
      ]
    },
    {
      path: '/lsrPlatform',
      name: 'LsrPlatform',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/LsrPlatform.vue'),
    },
    {
      path: '/lsrPlatform/lsrPlatformDetails/:id?/:level?',
      name: 'LsrPlatformDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/LsrPlatformDetails.vue'),
    },
    {
      path: '/base',
      name: 'Base',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/Base.vue'),
    },
    {
      path: '/base/baseDetails',
      name: 'BaseDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/BaseDetails.vue'),
    },
    {
      path: '/professor',
      name: 'Professor',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/Professor.vue'),
    },
    {
      path: '/professor/professorDetails',
      name: 'ProfessorDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/ProfessorDetails.vue'),
    },
    {
      path: '/achievement',
      name: 'Achievement',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/Achievement.vue'),
    },
    {
      path: '/achievement/achievementDetails',
      name: 'AchievementDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/AchievementDetails.vue'),
    },
    {
      path: '/facility',
      name: 'Facility',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/Facility.vue'),
    },
    {
      path: '/facility/facilityDetails',
      name: 'FacilityDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/FacilityDetails.vue'),
    },
    {
      path: '/project',
      name: 'Project',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/Project.vue'),
    },
    {
      path: '/project/projectDetails',
      name: 'ProjectDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/ProjectDetails.vue'),
    },
    {
      path: '/teachers',
      name: 'Teachers',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/Teachers.vue'),
    },
    {
      path: '/teachers/teachersDetails',
      name: 'TeachersDetails',
      meta: {
        navName: 'seven'
      },
      component: () => import('../../views/SevenLibraries/TeachersDetails.vue'),
    },
    {
      path:'/registry',
      name:'Registry',
      meta: {
        navName: 'footer'
      },
      component: () => import('../../views/Footers/Registry.vue'),
    },
    {
      path:'/serviceagreemrnt',
      name:'ServiceAgreemrnt',
      meta: {
        navName: 'footer'
      },
      component: () => import('../../views/Footers/ServiceAgreement.vue'),
    },
    {
      path:'/pardon',
      name:'Pardon',
      meta: {
        navName: 'footer'
      },
      component: () => import('../../views/Footers/Pardon.vue'),
    },
    {
      path:'/privacy',
      name:'Privacy',
      meta: {
        navName: 'footer'
      },
      component: () => import('../../views/Footers/Privacy.vue'),
    },
    {
      path:'/law',
      name:'Law',
      meta: {
        navName: 'footer'
      },
      component: () => import('../../views/Footers/Law.vue'),
    },

  ]
}


export default maintRouter