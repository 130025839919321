import request from '../common/utils/request'
import { white } from '@/enmu/whiteApi'

export function getToken() {
  return request({
    url: white.getToken,
    method: "GET",
  });
}

export function login(username, password) {
  return request({
    url: "/itf/common/login/login.json",
    method: "POST",
    params: {
      username,
      password
    }
  });
}

export function getInfo(token) {
  return request({
    url: "/itf/common/login/getLoginInfo.json",
    method: "get",
    params: token
  });
}

