import { toPageByid } from '../../api/SixLibraries/LsrPlatform/LsrPlatform'

const lsrPlatform = {
    state: {
        lsrDetailList: {},
    },

    mutations: {
        GETINFO(state, lsrDetailList) {
            state.lsrDetailList = lsrDetailList
        }
    },

    actions: {
        async getDetailsInfo({commit}, id) {
            let res = await toPageByid({id: id})
            if(res.success) {
                commit('GETINFO', res.data)
            }
        }
    }
}


export default lsrPlatform