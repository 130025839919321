const getters = {
  bizColumnsList: state => state.Info.bizColumnsList,   // 资讯栏目
  topHotInfo: state => state.Info.topHotInfo,   // 资讯栏目
  activeIndex: state => state.app.Nav.activeIndex, // 导航栏
  techfieldList: state => state.home.techfieldList, // 技术领域标签列表
  platLevelList: state => state.app.platLevel, // 技术领域标签列表
  loginInfo: state => state.app.loginInfo, // 技术领域标签列表
  userInfo: state => state.app.userInfo, // 技术领域标签列表
  firstTip: state => state.app.firstTip, // 第一次弹窗
}
/**
 * 使用说明
 * import { mapGetters } from 'vuex'
 * computed: {
    ...mapGetters([
      'bizColumnsList'
    ])
  }
 */

export default getters
