
import {
    getTechfield,
} from "../../api/home/home.js"
/**
 * 消息记录
 */
const app = {
    state: {
        techfieldList: [], // 技术领域
        // industryList:[] //行业领域
    },

    mutations: {
        /**
         * 更改待办事项
         */
         SET_TECHFIELD: (state, List) => {
            state.techfieldList = List
        },
        // 更改行业代办事项
        // SET_INDUSTRYFIELD: (state ,IndustryList) => {
        //     state.industryList = IndustryList
        // }
    },

    actions: {
        GetTechfield({
            state,
            commit
        }) {
            return new Promise((resolve, reject) => {
                getTechfield()
                    .then(response => {
                        commit('SET_TECHFIELD', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // GetIndustryfield({
        //     state,
        //     commit
        // }) {
        //     return new Promise((resolve, reject) => {
        //         getIndustryfield()
        //             .then(response => {
        //                 commit('SET_INDUSTRYFIELD', response.data)
        //                 resolve(response)
        //             })
        //             .catch(error => {
        //                 reject(error)
        //             })
        //     })
        // }


    }
}

export default app