import request from '../../common/utils/request'

// 获取资讯详情信息
export function getBizinfoById(data) {
  return request({
    url: "/itf/common/cjrh/info/getBizinfoById.json",
    method: "get",
    params: data
  });
}

// 获取最热几条
export function getHotBizinfos(data) {
  return request({
    url: "/itf/common/cjrh/info/getHotBizinfos.json",
    method: "get",
    params: data
  });
}

// 翻页查询
export function getBizinfos(data) {
  return request({
    url: "/itf/common/cjrh/info/getBizinfos.json",
    method: "get",
    params: data
  });
}



// 查询资讯栏目
export function getBizcolumns(data) {
  return request({
    url: "/itf/common/cjrh/info/getBizcolumns.json",
    method: "get",
    data: data
  });
}



